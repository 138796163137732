define("discourse/plugins/discourse-docs/discourse/components/docs-topic-list", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "discourse/plugins/discourse-docs/lib/get-docs"], function (_exports, _component, _object, _decorators, _getDocs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("order"), _dec2 = (0, _decorators.default)("order"), (_obj = {
    classNames: "docs-topic-list",
    urlPath: (0, _getDocs.getDocs)(),
    sortTitle(order) {
      return order === "title";
    },
    sortActivity(order) {
      return order === "activity";
    },
    sortListActivity() {
      this.sortBy("activity");
      return false;
    },
    sortListTitle() {
      this.sortBy("title");
      return false;
    }
  }, (_applyDecoratedDescriptor(_obj, "sortTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "sortTitle"), _obj), _applyDecoratedDescriptor(_obj, "sortActivity", [_dec2], Object.getOwnPropertyDescriptor(_obj, "sortActivity"), _obj), _applyDecoratedDescriptor(_obj, "sortListActivity", [_object.action], Object.getOwnPropertyDescriptor(_obj, "sortListActivity"), _obj), _applyDecoratedDescriptor(_obj, "sortListTitle", [_object.action], Object.getOwnPropertyDescriptor(_obj, "sortListTitle"), _obj)), _obj)));
});