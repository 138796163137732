define("discourse/plugins/discourse-docs/discourse/components/docs-search", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_obj = {
    classNames: "docs-search",
    onKeyDown(event) {
      if (event.key === "Enter") {
        this.set("searchTerm", event.target.value);
        this.onSearch(event.target.value);
      }
    },
    clearSearch() {
      this.set("searchTerm", "");
      this.onSearch("");
    }
  }, (_applyDecoratedDescriptor(_obj, "onKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onKeyDown"), _obj), _applyDecoratedDescriptor(_obj, "clearSearch", [_object.action], Object.getOwnPropertyDescriptor(_obj, "clearSearch"), _obj)), _obj));
});