define("discourse/plugins/discourse-docs/discourse/controllers/docs", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend((_obj = {
    indexController: (0, _controller.inject)("docs.index"),
    updateSelectedCategories(category) {
      this.indexController.send("updateSelectedCategories", category);
      return false;
    },
    updateSelectedTags(tag) {
      this.indexController.send("updateSelectedTags", tag);
      return false;
    },
    performSearch(term) {
      this.indexController.send("performSearch", term);
      return false;
    }
  }, (_applyDecoratedDescriptor(_obj, "updateSelectedCategories", [_object.action], Object.getOwnPropertyDescriptor(_obj, "updateSelectedCategories"), _obj), _applyDecoratedDescriptor(_obj, "updateSelectedTags", [_object.action], Object.getOwnPropertyDescriptor(_obj, "updateSelectedTags"), _obj), _applyDecoratedDescriptor(_obj, "performSearch", [_object.action], Object.getOwnPropertyDescriptor(_obj, "performSearch"), _obj)), _obj));
});