define("discourse/plugins/discourse-docs/discourse/components/docs-topic", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/lib/debounce", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _debounce, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("topic.post_stream"), _dec2 = (0, _decorators.default)("post", "topic"), (_obj = {
    classNames: "docs-topic",
    originalPostContent: (0, _computed.reads)("post.cooked"),
    post(stream) {
      return this.store.createRecord("post", stream?.posts.firstObject);
    },
    model() {
      const post = this.post;
      if (!post.topic) {
        post.set("topic", this.topic);
      }
      return post;
    },
    _emitScrollEvent() {
      this.appEvents.trigger("docs-topic:current-post-scrolled");
    },
    debounceScrollEvent() {
      (0, _debounce.default)(this, this._emitScrollEvent, 200);
    },
    didInsertElement() {
      this._super(...arguments);
      document.querySelector("body").classList.add("archetype-docs-topic");
      document.addEventListener("scroll", this.debounceScrollEvent);
    },
    willDestroyElement() {
      this._super(...arguments);
      document.querySelector("body").classList.remove("archetype-docs-topic");
      document.removeEventListener("scroll", this.debounceScrollEvent);
    }
  }, (_applyDecoratedDescriptor(_obj, "post", [_dec], Object.getOwnPropertyDescriptor(_obj, "post"), _obj), _applyDecoratedDescriptor(_obj, "model", [_dec2], Object.getOwnPropertyDescriptor(_obj, "model"), _obj), _applyDecoratedDescriptor(_obj, "_emitScrollEvent", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_emitScrollEvent"), _obj), _applyDecoratedDescriptor(_obj, "debounceScrollEvent", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "debounceScrollEvent"), _obj)), _obj)));
});